import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/download/:code/:name',
    name: 'download',
    meta: { title: '下载' },
    component: () => import('../views/download')
  },
  {
    path: '/private',
    name: 'private',
    meta: { title: '隐私协议' },
    component: () => import('../views/private')
  },
  {
    path: '/install',
    name: 'install',
    meta: { title: '下载' },
    component: () => import('../views/install')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  // base: '/download/',
})

export default router
