import router from '@/router'
let token = localStorage.getItem('token')
// 路由守卫
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  /*if(token) {
    if(to.path === '/login') {
      next('/')
    }else {
      next()
    }
  }else {
    if(to.path === '/login') {
      next()
    }else {

      next('/login')
    }
  }*/
  next()
})
