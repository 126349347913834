<template>
  <div class="custom_tabs" >
    <div class="head_wrap" >
      <div class="tabs" >
        <div class="tabs_item"
             v-for="(item, index) in tabList"
             :class="{ active: index === value }"
             :key="index"
             @click="tabChange(index)"
        >{{ item }}</div>
        <div class="extra" >
          <slot name="extra" ></slot>
        </div>
      </div>
      <div class="tabs_line" :style="{ transform: `translateX(${value * 21}vw)`,transition: 'all 0.3s' }" ></div>
    </div>
    <div class="tabs_content" >
      <slot></slot>
    </div>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
export default {
  name: 'customTabs',
  data() {
    return {
      active: 0,
    }
  },
  props: {
    tabList: Array,
    value: Number
  },
  methods: {
    tabChange(index) {
      this.$emit('change', index)
    }
  },
}
</script>
